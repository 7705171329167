var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "px-lg-5", attrs: { fluid: "" } }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "v-card",
          { staticClass: "rounded-card", attrs: { flat: "", outlined: "" } },
          [
            _c("assets-table", {
              attrs: {
                assets: _vm.assets,
                loading: _vm.loading,
                showActions: false
              },
              on: { updateOptions: _vm.updateOptions }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }